import React, { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./intro-slide.css";

export const IntroSlide = () => {
  // eslint-disable-next-line
  const { topicType, setTopicType, topicChosen, setTopicChosen } =
    useContext(TopicContext);
  const handleClick = (e) => {
    setTopicType((topic) => e.currentTarget.id);
    setTopicChosen((topic) => true);
  };

  return (
    <div className="intro-screen">
      <div className="intro-text">
        <h1>
          The future of nutrition will result from advanced understanding of
          "omics".
        </h1>
        <h2>
          The scientific world is expanding our knowledge of how biology works
          at the most minute levels. This requires an in-depth understanding of
          how DNA gets translated into cellular action. The study or tools being
          used to acquire this knowledge are collectively referred to as
          "omics".
        </h2>
      </div>
      <div className="topic-section-container">
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="genomics"
        >
          <img
            className="topic-image"
            alt="DNA strand"
            src="https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/genomics%20thumb.png"
          />
          <button className="topic-button">
            <h3>Genomics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="transcriptomics"
        >
          <img
            className="topic-image"
            alt="RNA strand"
            src="https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/transcriptomics%20thumb.png"
          />
          <button className="topic-button">
            <h3>Transcriptomics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="proteomics"
        >
          <img
            className="topic-image"
            alt="Fel-d1 protein"
            src="https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/proteomics%20thumb.png"
          />
          <button className="topic-button">
            <h3>Proteomics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="metabolomics"
        >
          <img
            className="topic-image"
            alt="DNA strand"
            src="https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/metabolomics%20thumb.png"
          />
          <button className="topic-button">
            <h3>Metabolomics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="metagenomics"
        >
          <img
            className="topic-image"
            alt="Healthy Gut Bacteria"
            src="https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/metabolomics%20thumb%20USE.png"
          />
          <button className="topic-button">
            <h3>Metagenomics</h3>
          </button>
        </div>
      </div>
    </div>
  );
};
