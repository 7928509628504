import React from "react";
import { useContext, useState, useEffect } from "react";
import { TopicContext } from "./TopicContext";
import "./media-content.css";
import Metabolomics from "./slides/Metabolomics";
import Genomics from "./slides/Genomics";
import Proteomics from "./slides/Proteomics";
import Transcriptomics from "./slides/Transcriptomics";
import Metagenomics from "./slides/Metagenomics";
import http from "../lib/http";

function Media() {
  const [slide, setSlide] = useState();
  // const [splashTitle, setSplashTitle] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/slides/`);
      setSlide(data);
    }
    fetchData();
  });

  const {
    topicType,
    // eslint-disable-next-line
    setTopicType,
    topicChosen,
    // eslint-disable-next-line
    setTopicChosen,
    showTitle,
    setShowTitle,
  } = useContext(TopicContext);

  const titleSlide =
    topicType === "genomics"
      ? "Genomics"
      : topicType === "metabolomics"
        ? "Metabolomics"
        : topicType === "proteomics"
          ? "Proteomics"
          : topicType === "transcriptomics"
            ? "Transcriptomics"
            : topicType === "metagenomics"
              ? "Metagenomics"
              : "Genomics";

  // const setTimer = () => {setShowTitle((topic) => true)
  //   setTimeout(() => {
  //     setShowTitle((topic) => false);
  //   }, 3000);}

  const handleClick = () => {
    setShowTitle((topic) => false);
  };

  return !showTitle && topicChosen && topicType === "genomics" ? (
    <Genomics {...slide} />
  ) : !showTitle && topicChosen && topicType === "transcriptomics" ? (
    <Transcriptomics {...slide} />
  ) : !showTitle && topicChosen && topicType === "proteomics" ? (
    <Proteomics {...slide} />
  ) : !showTitle && topicChosen && topicType === "metabolomics" ? (
    <Metabolomics {...slide} />
  ) : !showTitle && topicChosen && topicType === "metagenomics" ? (
    <Metagenomics {...slide} />
  ) : topicChosen ? (
    // setTimer(),
    <div className="central-media-container">
      <div className="title-slide" id={topicType}>
        <h1>
          {titleSlide}
          <hr />
        </h1>
        <div className="media-start-button" onClick={handleClick}>
          Next
        </div>
      </div>
    </div>
  ) : (
    <div className="central-media-container">
      <div className="central-media-content">
        <h2>
          Something Went Wrong
          <hr />
        </h2>
      </div>
    </div>
  );
}
export default Media;
